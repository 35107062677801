import axios from 'axios';

const apiCall = (opts) => {
  return new Promise((resolve, reject) => {
    opts = opts || {};
    opts.context = opts.context || null;
    opts.responseType = opts.responseType || 'application/json';
    let url = '';
    if (opts.url) {
      url = opts.url;
    } else {
      url = window.location.hostname == 'localhost' ? `https://srsrfl-staging.com/` : '/';
    }
    const headers = opts.headers || {};
    axios({
      method: opts.method.toLowerCase(),
      url: `${url}${opts.endpoint}`,
      responseType: opts.responseType,
      headers: headers,
      params: opts.params,
      data: opts.data,
    }).then(resolve).catch(reject);
  });
};

export default apiCall;
