import React from 'react';
import apiCall from '../../../../lib/apiCall.js';
import Error from '../../../../lib/error.js';
import Loading from '../../../../lib/loading';
import getErrorMessage from '../../../../lib/getErrorMessage.js';
import propTypes from 'prop-types';
import { Context } from '../context/context.js';
import { Typography, Grid, Container, Paper, TextField, Button } from '@mui/material';

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      loaded: true,
      view: 'Register',
      store_name: null,
      store_uuid: null,
      patient_uuid: null,
      new_patient_uuid: null,
      user_uuid: null,
      username: null,
      password: null,
      first_name: null,
      last_name: null,
      disabled: false,
      fields: fields,
      fields2: fields2,
    };
    const fields = {
      first_name: {
        title: 'First Name',
        type: {
          typename: 'text',
        },
        required: true,
        error: true,
        onChange: (value) => {
          const copy = { ...this.state };
          if (!/^[ -~]{1,20}$/.test(value.trim())) {
            fields.first_name.error = true;
            fields.first_name.feedback = 'Invalid First Name Format';
          } else {
            fields.first_name.error = false;
            fields.first_name.feedback = '';
          }
          copy.fields.first_name.value = value;
          this.setState(copy);
        },
        fullwidth: true,
        feedback: '',
        value: '',
      },
      last_name: {
        title: 'Last Name',
        type: {
          typename: 'text',
        },
        required: true,
        error: true,
        onChange: (value) => {
          const copy = { ...this.state };
          if (!/^[ -~]{1,20}$/.test(value.trim())) {
            fields.last_name.error = true;
            fields.last_name.feedback = 'Invalid Last Name Format';
          } else {
            fields.last_name.error = false;
            fields.last_name.feedback = '';
          }
          copy.fields.last_name.value = value;
          this.setState(copy);
        },
        fullwidth: true,
        feedback: '',
        value: '',
      },
      email: {
        title: 'Email Address',
        type: {
          typename: 'text',
        },
        required: true,
        error: true,
        onChange: (value) => {
          const copy = { ...this.state };
          if (!/^.+@(.+\.)+.+$/.test(value)) {
            fields.email.error = true;
            fields.email.feedback = 'Invalid Email Address Format';
          } else {
            fields.email.error = false;
            fields.email.feedback = '';
          }
          copy.fields.email.value = value;
          this.setState(copy);
        },
        fullwidth: true,
        feedback: '',
        value: '',
      },
      password: {
        title: 'Password',
        type: {
          typename: 'password',
        },
        required: true,
        error: true,
        onChange: (value) => {
          const copy = { ...this.state };
          copy.fields.password.value = value;
          if (value.length == 0) {
            fields.password.error = true;
            fields.password.feedback = (
              <>
                <li>At least 8 characters long</li>
                <li>At least one special character</li>
                <li>At least one capital letter</li>
              </>
            );
          } else {
            if (!/[ -/:-@[-`{-~]/.test(value)) {
              fields.password.error = true;
              fields.password.feedback = 'At least 1 special character is required';
            } else if (!/[A-Z]/.test(value)) {
              fields.password.error = true;
              fields.password.feedback = 'At least 1 capital letter is required';
            } else if (value.length < 8) {
              fields.password.error = true;
              fields.password.feedback = 'At least 8 characters is required';
            } else {
              fields.password.error = false;
              fields.password.feedback = '';
            }
            if (copy.fields.confirm.value !== copy.fields.password.value) {
              copy.fields.confirm.error = true;
              copy.fields.confirm.feedback = 'Passwords must match';
            } else {
              copy.fields.confirm.error = false;
              copy.fields.confirm.feedback = '';
            }
          }
          this.setState(copy);
        },
        fullwidth: true,
        feedback: (
          <>
            <li>At least 8 characters long</li>
            <li>At least one special character</li>
            <li>At least one capital letter</li>
          </>
        ),
        value: '',
      },
      confirm: {
        title: 'Confirm Password',
        type: {
          typename: 'password',
        },
        required: true,
        error: true,
        onChange: (value) => {
          const copy = { ...this.state };
          copy.fields.confirm.value = value;
          if (copy.fields.confirm.value.length == 0) {
            copy.fields.confirm.error = true;
            copy.fields.confirm.feedback = 'Passwords must match';
          } else {
            if (copy.fields.confirm.value !== copy.fields.password.value) {
              copy.fields.confirm.error = true;
              copy.fields.confirm.feedback = 'Passwords must match';
            } else {
              copy.fields.confirm.error = false;
              copy.fields.confirm.feedback = '';
            }
          }
          this.setState(copy);
        },
        fullwidth: true,
        feedback: '',
        value: '',
      },
      value: '',
      error: false,
      feedback: '',
    };
    const fields2 = {
      confirmation: {
        title: 'Confirmation Code',
        type: {
          typename: 'text',
        },
        required: true,
        error: true,
        onChange: (value) => {
          const copy = { ...this.state };
          if (!/^[ -~]{1,20}$/.test(value)) {
            fields2.confirmation.error = true;
            fields2.confirmation.feedback = 'Invalid Confirmation Code Format';
          } else {
            fields2.confirmation.error = false;
            fields2.confirmation.feedback = '';
          }
          copy.fields2.confirmation.value = value;
          this.setState(copy);
        },
        fullwidth: true,
        feedback: '',
        value: '',
      },
    };
    this.state.fields = fields;
    this.state.fields2 = fields2;
    this.clearFields = this.clearFields.bind(this);
    this.changeView = this.changeView.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  static contextType = Context;

  static propTypes = {
    store_uuid: propTypes.string,
    store_name: propTypes.string,
    register_enabled: propTypes.string,
  };

  clearFields() {
    const copy = { ...this.state };
    for (const key in copy.fields) {
      if (copy.fields[key].value !== undefined) {
        copy.fields[key].value = '';
        if (copy.fields[key].required) {
          copy.fields[key].error = true;
        }
      }
    }
    if (copy.fields.password.value !== undefined) {
      copy.fields.password.feedback = (
        <>
          <li>At least 8 characters long</li>
          <li>At least one special character</li>
          <li>At least one capital letter</li>
        </>
      );
    }
    this.setState(copy);
  }

  changeView(view) {
    this.setState({ view: view });
  }

  clearState() {
    const copy = { ...this.state };
    copy.errors = null;
    copy.loaded = true;
    copy.view = 'Register';
    copy.store_name = null;
    copy.store_uuid = null;
    copy.patient_uuid = null;
    copy.new_patient_uuid = null;
    copy.user_uuid = null;
    copy.username = null;
    copy.password = null;
    this.context.removeItem('password');
    this.context.removeItem('user_uuid');
    this.context.removeItem('patient_uuid');
    this.context.removeItem('first_name');
    this.context.removeItem('last_name');
    this.setState(copy);
  }

  registerUser() {
    const copy = { ...this.state };
    const params = {
      first_name: this.state.fields.first_name.value,
      last_name: this.state.fields.last_name.value,
      email: this.state.fields.email.value,
      patient_uuid: this.context.getPatient(),
      store_uuid: this.props.store_uuid,
    };
    const options = {
      method: 'post',
      url: 'https://api-staging.srspharmacy.com/',
      endpoint: 'public/v1/auth/register',
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    };
    this.setState({ loaded: false });
    apiCall(options).then( (resp) => {
      const r = JSON.parse(resp.data);
      this.setState({
        username: copy.fields.email.value,
        password: copy.fields.password.value,
        first_name: copy.fields.first_name.value,
        last_name: copy.fields.last_name.value,
        user_uuid: r.user.user_uuid,
        new_patient_uuid: r.patient_user.patient_uuid,
        loaded: true,
      });
      this.context.setUserUUID(r.user.user_uuid);
      this.context.setPassword(this.state.fields.password.value);
      this.context.setUsername(this.state.fields.email.value);
      this.context.setPatient(r.patient_user.patient_uuid);
      this.context.setFirstName(this.state.fields.first_name.value);
      this.context.setLastName(this.state.fields.last_name.value);
      this.changeView('Confirmation');
    }).catch( (err) => {
      console.log(err);
      this.setState({ errors: getErrorMessage(err), loaded: true });
    });
  }

  confirmCode() {
    const copy = { ...this.state };
    const params = {
      username: this.context.getUsername(),
      password: this.context.getPassword(),
      store_uuid: this.props.store_uuid,
      user_uuid: this.context.getUserUUID(),
      patient_uuid: this.context.getPatient(),
      code: this.state.fields2.confirmation.value,
      first_name: this.context.getFirstName(),
      last_name: this.context.getLastName(),
    };
    const options = {
      method: 'post',
      url: 'https://api-staging.srspharmacy.com/',
      endpoint: 'auth/patient/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    };
    apiCall(options).then( (resp) => {
      const r = JSON.parse(resp.data);
      this.context.setAuthTokens(r);
      this.clearState();
      window.location.href = 'portal.html?store_uuid=' + this.props.store_uuid;
    }).catch( (err) => {
      console.log(err);
      copy.fields2.confirmation.error = true;
      copy.fields2.confirmation.feedback = 'Incorrect Confirmation Code';
      copy.disabled = false;
      this.setState(copy);
    });
  }

  render() {
    let registerSubtitle = '';
    if (this.props.register_enabled == 'Y') {
      registerSubtitle = `To submit your refill request, register for ${this.props.store_name}'s Patient Portal.`;
    }
    let body = (
      <p>Not Implemented Yet</p>
    );
    if (!this.state.loaded) {
      body = (
        <Container maxWidth='lg' sx={{ padding: '20px ' }}>
          <Loading/>
        </Container>
      );
    } else if (this.state.errors) {
      let error = '';
      if (this.state.errors == 'Invalid data format' || this.state.errors == 'Unexpected value provide') {
        error = 'Missing or invalid store tag in request';
      } else if (this.state.errors
        == 'Not enough parameters supplied in request'
        || this.state.errors
        == 'Unexpected/Missing query string supplied') {
        error = 'Missing store tag or Rx number in query string';
      } else if (this.state.errors == 'Invalid data supplied in the request') {
        error = 'Invalid hash given in the query string';
      } else if (this.state.errors.includes('User already exist')) {
        error = 'A user with that email already exists.';
      } else if (this.state.errors.includes('Email does not match what is associated to the patient.')) {
        error = 'Email does not match what is associated to the patient. '
        + 'Please contact ' + this.props.store_name + ' to register.';
      }
      const props = {
        message: error ? error : this.state.errors,
      };
      body = (
        <Container maxWidth='lg' sx={{ padding: '20px ' }}>
          {Error(props)}
          <Grid container direction='row' justifyContent='space-around' alignItems='center'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.changeView(this.state.view);
                  this.setState({ errors: false });
                }}>
            Back
              </Button>
            </Grid>
            {
              error == 'A user with that email already exists.'
              ? <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.location.href = 'portal.html?store_uuid=' + this.props.store_uuid;
                  }}>
                  Log In
                </Button>
              </Grid>
              : <></>
            }
          </Grid>
        </Container>
      );
    } else {
      switch (this.state.view) {
        case 'Register':
          if (this.context.getPassword()) {
            this.changeView('Confirmation');
          }
          body = (
            <form id='register'>
              <Grid container spacing={1} sx={{ padding: '20px ' }}>
                <Grid item xs={12}>
                  <Typography variant='h4'>
                      User Registration
                  </Typography>
                  <Typography variant='subtitle1'>
                    {registerSubtitle}
                  </Typography>
                  <hr/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={this.state.fields.first_name.error}
                        required={this.state.fields.first_name.required}
                        size='small'
                        type='text'
                        label={this.state.fields.first_name.title}
                        helperText={
                            this.state && this.state.fields.first_name.error
                              ? this.state.fields.first_name.feedback
                              : ''
                        }
                        value={this.state.fields.first_name.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.state.fields.first_name.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={this.state.fields.last_name.error}
                        required={this.state.fields.last_name.required}
                        size='small'
                        type='text'
                        label={this.state.fields.last_name.title}
                        helperText={
                            this.state && this.state.fields.last_name.error
                              ? this.state.fields.last_name.feedback
                              : ''
                        }
                        value={this.state.fields.last_name.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.state.fields.last_name.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={this.state.fields.email.error}
                        required={this.state.fields.email.required}
                        size='small'
                        type='text'
                        label={this.state.fields.email.title}
                        helperText={
                            this.state && this.state.fields.email.error
                              ? this.state.fields.email.feedback
                              : ''
                        }
                        value={this.state.fields.email.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.state.fields.email.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <TextField
                        fullWidth
                        error={this.state.fields.password.error}
                        required={this.state.fields.password.required}
                        size='small'
                        type='password'
                        label={this.state.fields.password.title}
                        helperText={
                            this.state && this.state.fields.password.error
                              ? this.state.fields.password.feedback
                              : ''
                        }
                        value={this.state.fields.password.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.state.fields.password.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <TextField
                        fullWidth
                        error={this.state.fields.confirm.error}
                        required={this.state.fields.confirm.required}
                        size='small'
                        type='password'
                        label={this.state.fields.confirm.title}
                        helperText={
                            this.state && this.state.fields.confirm.error
                              ? this.state.fields.confirm.feedback
                              : ''
                        }
                        value={this.state.fields.confirm.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.state.fields.confirm.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '5px', bgcolor: 'rgba(0,0,0,.05' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Button
                            className='reset'
                            variant='contained'
                            color='error'
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.clearFields();
                            }}
                          >
                              Reset
                          </Button>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            className='save'
                            variant='contained'
                            color='primary'
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              const fields = this.state.fields;
                              let submit = true;
                              for (const field in fields) {
                                if (fields[field].error) {
                                  submit = false;
                                }
                              }
                              if (submit) {
                                this.registerUser();
                              }
                            }}
                          >
                              Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
          break;
        case 'Confirmation':
          body = (
            <form id='confirmation'>
              <Grid container spacing={1} sx={{ padding: '20px' }}>
                <Grid item xs={12}>
                  <Typography variant='h4'>Email Confirmation</Typography>
                  <Typography variant='subtitle1'>Check your email for your confirmation code.</Typography>
                  <hr/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={this.state.fields2.confirmation.error}
                        required={this.state.fields2.confirmation.required}
                        size='small'
                        type='text'
                        label={this.state.fields2.confirmation.title}
                        helperText={
                            this.state && this.state.fields2.confirmation.error
                              ? this.state.fields2.confirmation.feedback
                              : ''
                        }
                        value={this.state.fields2.confirmation.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          this.state.fields2.confirmation.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '5px', bgcolor: 'rgba(0,0,0,.05' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            className='save'
                            variant='contained'
                            color='primary'
                            disabled={this.state.disabled}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              const fields2 = this.state.fields2;
                              let submit = true;
                              for (const field in fields2) {
                                if (fields2[field].error) {
                                  submit = false;
                                }
                              }
                              if (submit) {
                                this.setState({ disabled: true });
                                this.confirmCode();
                              }
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
          break;
      }
    }
    return (
      <Container maxWidth='lg'>
        <Paper variant='outlined'>
          {body}
        </Paper>
      </Container>
    );
  }
}

export default RegistrationForm;
