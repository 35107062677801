import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../../../lib/loading.js';
import Error from '../../../../lib/error.js';
import { Context } from '../context/context.js';
import apiCall from '../../../../lib/apiCall.js';
import getErrorMessage from '../../../../lib/getErrorMessage.js';
import RegistrationForm from '../components/RegistrationForm.js';
import { AppBar, Toolbar, Button, Typography, Grid, Box, Paper, Container } from '@mui/material';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      loaded: false,
      submit: false,
      redirect: false,
      tag: this.getStoreNumber(),
      rxNumber: this.getRxNumber(),
      hash: this.getHash(),
      patient_user: false,
      store_name: '' || null,
      logo: null,
      store_address: '' || null,
      store_address_two: '' || null,
      city: '' || null,
      state: '' || null,
      zip: '' || null,
      pharmacy_phone: '' || null,
      store_uuid: '' || null,
      requested: '' || null,
      disable_button: false,
      showForm: false,
      register_enabled: 'N',
      showRegisterButton: false,
    };
    this.requestRefill = this.requestRefill.bind(this);
    this.getFullAuth = this.getFullAuth.bind(this);
    this.getHash = this.getHash.bind(this);
    this.getStoreNumber = this.getStoreNumber.bind(this);
    this.getRxNumber = this.getRxNumber.bind(this);
    this.constructXMLDocument = this.constructXMLDocument.bind(this);
    this.entity = this.entity.bind(this);
    this.load = this.load.bind(this);
  }

  static propTypes = {
    prescription: PropTypes.object,
    setRefillResponse: PropTypes.func,
    themeMode: PropTypes.string.isRequired,
  };

  static contextType = Context;

  getFullAuth() {
    if (window.location.search.substring(1) == 'demo'
    || window.location.search.substring(1) == 't=demo') {
      return window.location.href = 'https://srsrfl-staging.com/?t=1Hs1F2y771027101086';
    } else if (window.location.search.substring(1, 3) == 't=') {
      return window.location.search.substring(3);
    } else {
      return window.location.search.substring(1);
    }
  }

  getHash() {
    return this.getFullAuth().substring(0, 7);
  }

  getStoreNumber() {
    return this.getFullAuth().substring(7, 13);
  }

  getRxNumber() {
    return this.getFullAuth().substring(13).replace(/[^0-9]/g, '');
  }

  entity(...args) {
    const [doc, entityName, ...extra] = args;
    const node = doc.createElement(entityName);
    for (const arg of extra) {
      if (!arg) {
        node.appendChild(doc.createTextNode(''));
      } else if (typeof arg === 'string') {
        node.appendChild(doc.createTextNode(arg));
      } else {
        node.appendChild(arg);
      }
    }
    return node;
  }

  constructXMLDocument(lastname, firstname, phone, email, message) {
    const doc = document.implementation.createDocument('http://www.srspharmacy.com/XML/PatientRefillRequest',
        'PatientRefillRequest',
        null);

    const root = doc.documentElement;
    root.appendChild(this.entity(doc, 'To', this.state.tag));
    root.appendChild(this.entity(doc, 'From', 'srsrfl'));
    root.appendChild(this.entity(doc, 'Auth', this.state.hash));
    root.appendChild(this.entity(doc, 'SentTime', new Date().toISOString()));
    root.appendChild(this.entity(doc, 'MessageID', new Date().getTime().toString()));
    root.appendChild(this.entity(doc, 'RxNumbers',
        this.entity(doc, 'RxNumber', this.state.rxNumber)));
    root.appendChild(this.entity(doc, 'Contact',
        this.entity(doc, 'LastName', lastname),
        this.entity(doc, 'FirstName', firstname),
        this.entity(doc, 'Phone', phone),
        this.entity(doc, 'Email', email)));
    root.appendChild(this.entity(doc, 'Comment', message));
    return doc;
  }

  load() {
    const params = {
      tag: this.getStoreNumber(),
      rx_number: this.getRxNumber(),
      auth: this.getHash(),
    };
    const options = {
      method: 'get',
      endpoint: 'query',
      params: params,
    };
    apiCall(options).then( (resp) => {
      const r = JSON.parse(resp.data);
      this.setState({
        store_name: r.name,
        logo: r.logo,
        store_address: r.address1,
        store_address_two: r.address2,
        city: r.city,
        state: r.state,
        zip: r.zip,
        pharmacy_phone: r.phone,
        store_uuid: r.store_uuid,
        loaded: true,
        register_enabled: r.srsrfl_register,
        showForm: r.srsrfl_register == 'Y' ? true : false,
        patient_user: r.users && r.users.length > 0 ? true : false,
      });
      if (r.patient_uuid) {
        this.context.setPatient(r.patient_uuid);
      }
    }).catch( (err) => {
      console.log(err);
      const data = err?.response?.data ? JSON.parse(err.response.data) : '';
      if (!data) {
        this.setState({ errors: 'Something unexpected happened', loaded: true });
      } else if (data.redirect) {
        this.setState({ redirect: true, loaded: true });
      } else {
        const message = data.message;
        this.setState({ errors: getErrorMessage(message), loaded: true });
      }
    });
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    if (!this.state.loaded) {
      this.load();
    }
  }

  requestRefill() {
    const refill = '<?xml version="1.0"?>' + new XMLSerializer().serializeToString(this.constructXMLDocument());
    const options = {
      method: 'post',
      endpoint: 'submit',
      headers: {
        'Content-Type': 'text/xml',
      },
      data: refill,
    };
    apiCall(options).then( (resp) => {
      this.setState({
        requested: 'Refill requested successfully.',
        showRegisterButton: this.state.register_enabled == 'O' ? true : false,
      });
    }).catch( (err) => {
      console.log(err);
      this.setState({ errors: getErrorMessage(err), loaded: true });
    });
  }

  render() {
    let body = (
      <Loading/>
    );
    if (!this.state.loaded) {
      body = (
        <Loading/>
      );
    } else if (this.state.redirect) {
      const storetag = this.getStoreNumber();
      window.location.href = 'webrefillform/' + storetag;
    } else if (this.state.errors) {
      let error = '';
      if (this.state.errors == 'Invalid data format' || this.state.errors == 'Unexpected value provide') {
        error = 'Missing or invalid store tag in request';
      // eslint-disable-next-line no-multi-spaces
      } else if (   this.state.errors == 'Not enough parameters supplied in request'
                 || this.state.errors == 'Unexpected/Missing query string supplied') {
        error = 'Missing store tag or Rx number in query string';
      } else if (this.state.errors == 'Invalid data supplied in the request') {
        error = 'Invalid hash given in the query string';
      }
      const props = {
        message: error ? error : this.state.errors,
      };
      if (this.state.store_name) {
        props.returnTo = () => this.setState({ errors: null });
      }
      body = (
        Error(props)
      );
    } else {
      if (this.state.patient_user) {
        window.location.href = 'portal.html?store_uuid=' + this.state.store_uuid + '#/';
      } else {
        body = (
          <Grid>
            <AppBar position="static">
              <Toolbar>
                {
                  this.state.logo ? (
                    <img
                      src={this.state.logo}
                      alt={this.state.store_name}
                      style={{ margin: '5px', borderRadius: '5px', height: '5rem' }}
                    />
                  ) : (
                    <Typography>{this.state.store_name}</Typography>
                  )
                }
              </Toolbar>
            </AppBar>
            <Grid container justifyContent='center'>
              <Grid item>
                <Box>
                  <Typography
                    variant='h6'
                  >Refill Request for Rx# {this.state.rxNumber}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <br />
            <Grid container direction='column' justifyContent='space-evenly' alignItems='center' spacing={2}>
              <Grid item>
                {
                    this.state.requested
                    ? (
                      <Container maxWidth='lg'>
                        <Paper square={false} elevation={0} variant='outlined'
                          sx={{ color: 'green', py: '0.5rem', px: '1rem' }}>
                          <Typography variant='h5' align='center'> {this.state.requested}</Typography>
                        </Paper>
                      </Container>
                      )
                    : <></>
                }
              </Grid>
              <Grid item>
                {
                  this.state.showForm && this.state.register_enabled !== 'N'
                    ? <RegistrationForm
                      store_uuid={this.state.store_uuid}
                      store_name={this.state.store_name}
                      register_enabled={this.state.register_enabled}/>
                    : <></>
                }
              </Grid>
              <Grid item>
                {
                  !this.state.showForm && !this.state.showRegisterButton
                    ? <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={this.state.disable_button}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.requestRefill(e);
                          this.setState({
                            disable_button: true,
                          });
                        }}>
                        Request Refill
                      </Button>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                    : <></>
                }
              </Grid>
              {
                  this.state.showRegisterButton
                  ? <div>
                    <Grid container direction="column" alignItems="center" width="100%">
                      <Grid item>
                        <Typography sx={{ textAlign: 'center', margin: '2rem' }}>
                          {/* eslint-disable-next-line max-len */}
                          Optional: To make this process easier next time, press Continue to begin registering for {this.state.store_name}&apos;s Patient Portal.
                        </Typography>
                      </Grid>
                      <Grid item >
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.setState({
                              showRegisterButton: false,
                              showForm: true,
                            });
                          }}>
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                  : <></>
              }
            </Grid>
            <Grid container direction='row' justifyContent='space-around' alignItems='center'>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  href={ '/m/' + this.state.tag }
                >Mobile Site</Button>
              </Grid>
              <Grid item>
                <Button
                  href={'tel://+1' + this.state.pharmacy_phone.replace(/[^+\d]/g, '')}
                  variant="outlined"
                  size="large">
                  Call Pharmacy
                </Button>
              </Grid>
            </Grid>
            <hr/>
            <Grid container direction='column' justifyContent='flex-end' alignItems='center'>
              <Typography>{this.state.store_name}</Typography>
              <Typography>{this.state.store_address}</Typography>
              <Typography>{this.state.city}, {this.state.state} {this.state.zip}</Typography>
              <Typography>{this.state.pharmacy_phone}</Typography>
            </Grid>
          </Grid>
        );
      }
    }
    return (
      <div>
        {body}
      </div>
    );
  }
}

export default Home;
