import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Typography, Button, Container } from '@mui/material';
import LoadingCapsuleImg from '../img/LoadingCapsule.png';

const Error = (props) => {
  return (
    <Container maxWidth='lg'>
      <Grid container direction='column' justifyContent='center' alignItems='center'>
        <Grid item>
          <img src={LoadingCapsuleImg} />
        </Grid>
        <Grid item>
          <Typography variant='h6'>
            Error: {props.message}
          </Typography>
        </Grid>
        <Grid item>
          { props.returnTo
            && (
              <Button
                variant='contained'
                width='50%'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.returnTo();
                }}
              >
              Back
              </Button>
            )
          }
        </Grid>
      </Grid>
    </Container>
  );
};

Error.propTypes = {
  message: PropTypes.string.isRequired,
  returnTo: PropTypes.func,
};

export default Error;
