const getErrorMessage = (err) => {
  let error = '';
  if (err.response && err.response.data) {
    if (err.response.data.message) {
      if (err.response.data.message == 'string') {
        error = err.response.data.message;
      } else {
        error = JSON.stringify(err.response.data.message);
      }
    } else if (typeof err.response.data == 'string') {
      if (err.response.data.includes('message')) {
        error = err.response.data.substring(12, (err.response.data.length - 2));
      }
    } else {
      error = JSON.stringify(err.response.data);
    }
  } else {
    error = err.message ? err.message : JSON.stringify(err);
  }
  return error;
};

export default getErrorMessage;
