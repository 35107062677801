import React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Container,
} from '@mui/material';
class Loading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const body = (
      <Container maxWidth='lg'>
        <Paper
          style={{
            padding: '5px',
            marginTop: '10px',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  paddingTop: 100,
                  paddingBottom: 10,
                }}
              >
                <Typography variant='h3'>Loading...</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  paddingBottom: 10,
                }}
              >
                <CircularProgress />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  paddingBottom: 100,
                }}
              >
                <Typography variant='subtitle1'>This may take a moment.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
    return (
      <div>
        {body}
      </div>
    );
  }
}

export default Loading;
