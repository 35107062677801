import React from 'react';
import { Context } from './context/context.js';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

class App extends React.Component {
  #darkMode = null;
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      theme: 'light',
    };
    this.themeLight = createTheme({
      palette: {
        mode: 'light',
        background: {
          default: '#efefef',
        },
      },
    });
    this.themeDark = createTheme({
      palette: {
        mode: 'dark',
      },
    });
    this.updateTheme = this.updateTheme.bind(this);
    this.prefersDarkMode = this.prefersDarkMode.bind(this);
    this.setPatient = this.setPatient.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.getUsername = this.getUsername.bind(this);
    this.getPatient = this.getPatient.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.getPassword = this.getPassword.bind(this);
    this.setUserUUID = this.setUserUUID.bind(this);
    this.getUserUUID = this.getUserUUID.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.getFirstName = this.getFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.getLastName = this.getLastName.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.setTokens = this.setTokens.bind(this);
  }

  async componentDidMount() {
    this.prefersDarkMode();
  }

  async componentWillUnmount() {
    if (this.#darkMode) {
      this.#darkMode.removeEventListener('change', this.updateTheme);
      this.#darkMode = null;
    }
  }

  prefersDarkMode() {
    this.updateTheme();
    this.#darkMode = window.matchMedia('(prefers-color-scheme: dark)');
    this.#darkMode.addEventListener('change', this.updateTheme);
  }

  updateTheme(e = null) {
    if (!e) {
      e = window.matchMedia('(prefers-color-scheme: dark)');
    }
    const theme = e.matches ? 'dark' : 'light';
    this.setState({ theme });
  }

  setPatient(data) {
    localStorage.setItem('patient_uuid', data);
  }

  setUsername(data) {
    localStorage.setItem('username', data);
  }

  getUsername() {
    try {
      return localStorage.getItem('username');
    } catch (e) {
      return null;
    }
  }

  getPatient() {
    try {
      return localStorage.getItem('patient_uuid');
    } catch (e) {
      return null;
    }
  }

  setTokens(data) {
    localStorage.setItem('tokens', JSON.stringify(data));
    this.setState({ authTokens: data });
  }

  setPassword(data) {
    localStorage.setItem('password', data);
  }

  getPassword() {
    try {
      return localStorage.getItem('password');
    } catch (e) {
      return null;
    }
  }

  setUserUUID(data) {
    localStorage.setItem('user_uuid', data);
  }

  getUserUUID() {
    try {
      return localStorage.getItem('user_uuid');
    } catch (e) {
      return null;
    }
  }

  setFirstName(data) {
    localStorage.setItem('first_name', data);
  }

  getFirstName() {
    try {
      return localStorage.getItem('first_name');
    } catch (e) {
      return null;
    }
  }

  setLastName(data) {
    localStorage.setItem('last_name', data);
  }

  getLastName() {
    try {
      return localStorage.getItem('last_name');
    } catch (e) {
      return null;
    }
  }

  removeItem(data) {
    localStorage.removeItem(data);
  }

  render() {
    const vals = {
      authTokens: this.state.authTokens,
      setUsername: this.setUsername,
      getUsername: this.getUsername,
      setPatient: this.setPatient,
      getPatient: this.getPatient,
      setPassword: this.setPassword,
      getPassword: this.getPassword,
      setUserUUID: this.setUserUUID,
      getUserUUID: this.getUserUUID,
      setFirstName: this.setFirstName,
      getFirstName: this.getFirstName,
      setLastName: this.setLastName,
      getLastName: this.getLastName,
      removeItem: this.removeItem,
      setAuthTokens: this.setTokens,
    };
    return (
      <Context.Provider value={vals}>
        <ThemeProvider theme={this.state.theme === 'dark' ? this.themeDark : this.themeLight}>
          <CssBaseline/>
          <div>
            <HashRouter>
              <Routes>
                <Route path='/' element={<Home themeMode={this.state.theme} />} />
              </Routes>
            </HashRouter>
          </div>
        </ThemeProvider>
      </Context.Provider>
    );
  }
}

export default App;
